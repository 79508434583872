//calloutBanner List
@import '../../scss/variables';

.buttonGroup{
    display:flex !important;
    flex-direction: row;
    justify-content: center !important;
    display: block;
}
.buttonDot{
    border: 2px solid;
    border-radius: 1px;
    height:14px;
    width:14px;
    border-radius: 14px;
    margin-right: 14px;
}

.container {
    display: flex;
    margin: 48px auto 16px;
    width:100%;

    // THEMES
    &[data-theme='dark'] {
        .accentContainer {
            background-color: $grey;
        }

        .button {
            border-color: $white;

            &:hover {
                background-color: $white;
                
                [class='linkText'] {
                    color: $grey;
                }
            }
        }

        .link {
            &:hover {
                [class='linkText'] {
                    border-color: $white;
                }
            }

            &,
            &:hover {
                &::after {
                    color: $white;
                }
            }
        }

        [class='linkText'] {
            color: $white;
        }
    }

    &[data-theme='light'] {
        .accentContainer {
            background-color: $lightergrey;
        }

        .button {
            &:hover {
                background-color: $red;
                
                [class='linkText'] {
                    color: $lightergrey;
                }
            }
        }
    }

    // THEMES
    @each $name, $color in $colors {
        &[data-accentColor="#{$name}"] {
            .accentContainer::before {
                background-color: $color;
            }
        }
    }
}


// IMAGE/NO IMAGE
.hasImage {
    .imageContainer {
        flex-shrink: 0;
        overflow: hidden;
        position: relative;
        width: calc(100% / 3);

        .image {
            height: auto;
            left: 50%;
            max-width: none;
            min-height: 100%;
            object-fit: cover;
            object-position: center;
            width: 100%;
            min-width: 100%;
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
            // width: auto;
        }
    }

    .accentContainer {
        align-items: flex-start;
        flex-direction: column;
        justify-content: center;
        padding: 88px 61px 71px;
    }

    .title {
        margin-bottom: 32px;
    }
}

.noImage {
    .accentContainer {
        align-items: center;
        justify-content: space-between;
        padding: 111px 88px 135px;
    }

    .title {
        margin-right: 48px;
    }

    .ctaContainer {
        flex-shrink: 0;
    }
}

.accentContainer {
    display: flex;
    position: relative;
    width: 100%;

    &::before {
        content: '';
        display: block;
        height: 12px;
        left: 0;
        max-width: 465px;
        position: absolute;
        top: 0;
        width: 50%;
    }
}

.title {
    margin-top: 12px;
}

.ctaContainer {
    align-items: flex-start;
    display: flex;
    flex-direction: column;

    [class='linkText'] {
        font-size: 16px;
        font-family: $sans-serif-medium;
        font-weight: $sans-medium;
        letter-spacing: 1.5px;
        text-transform: uppercase;
    }
}

.button {
    border: 1px solid;
    text-align: center;
    padding: 10px 16px 8px;

    + .link {
        margin-top: 24px;
    }
}

@media (max-width: 840px) {
    .container {
        display: block;
    }

    .hasImage,
    .noImage {
        .accentContainer {
            padding: 40px 32px;
        }
    }

    .hasImage {
        .imageContainer {
            &,
            .image {
                width: 100%;
            }

            .image {
                display: block;
                position: initial;
                transform: none;
            } 
        }
    }

    .noImage {
        .accentContainer {
            align-items: flex-start;
            flex-direction: column;
            justify-content: center;
        }

        .title {
            margin-bottom: 32px;
        }
    }

    .accentContainer::before {
        height: 8px;
    }

    .title {
        font-size: 28px;
        line-height: 32px;
    }

    .ctaContainer,
    .button {
        width: 100%;
    }

    .ctaContainer {
        align-items: center;
    }
}

//Carousel styling
.animating {
    transition: all 500ms;
}

section[class*='list-feed-container'] {
    display: flex;
    flex-direction: column;
    width:100%;
    overflow-x: hidden;   
    
}

.hiddenSlide{
    visibility: hidden;
    pointer-events:none;
}



div[class*="bannerList-carousel-container"]

{

    position: relative;
    padding: 0 40px;
    width:200%;

    div[class*="inner-slide"] {
        border: none !important;
    }
    
        .noIcon {
            display: flex;
            flex-direction: row;
            align-items: flex-start;

        }      
       .userContainer{
            display: flex;
            flex-direction: row-reverse;
            flex-wrap: wrap;
            justify-content: space-between;
            i.icon {font-size: 24px;}
        }
        .noIcon h3 { 
            text-align: left;
        }
        .userContent {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            min-height: 250px;
            p {font-size:.83rem; line-height: 24px;}
            .date {
                margin-top: 10px;
            }
            a[class*="contentParse"]{
                font-size: 14px;
            }
        }
        span[class*="reTweeted"] {
            color:$anothergrey;
            i.icon{
                color: $anothergrey;
            }
            a {
                font-size: 14px;
            }
        }
        .userAvatar {
            width: 60px;    
            border-radius: 50%;
            margin-right: 10px;
        }
        .socialContainer {
            align-self: flex-end;
            height: 22px;
            margin-top: 20px; 
            margin-bottom: 20px;
        }
        a.socialInteraction {
            cursor: pointer;
            color: $grey;
            font-size: large;
            border-bottom: 0px;
            padding-left: 8px;
            .icon {
                font-size:  large;
                padding: 7px 5px;
                color: $grey;
                &:not(:last-child)
                {
                    margin-right: 6px;
                }
            }
            &:hover,
            &:hover > .icon {
                color:$darkred;
            }
        }
}
@media screen and (min-width: 1025px){
    .carouselBannerContainer{
        // padding: 0% 0.5% 0% 0.5%;
        }
     ul[class*="carouselDots"]{
        visibility: visible;
        display: inline-flex!important;
        margin-top: 16px;
        text-align: center;
        margin: 10px 39% 20px;
        li {
            display: inline-flex;
        }

        li::before{
            font-size: 0;
        }

        li button {
            background-color: #d9d9d9;
            border-radius: 50%;
            color: transparent;
            font-size: 0;
            height: 10px;
            line-height: 0;
            width: 10px;
        }

        li[class="slick-active"] button {
            background-color: #4d4d4d;
        }
    }        
}
.loaderContainer{
    display:flex;
    align-items: center;
    flex-direction: column;
    margin:30px 30px;
}
.loader {
    display: inline-block;
}
.loader:after {
    content: " ";
    display: block;
    width: 45px;
    height: 45px;
    margin: 8px;
    border-radius: 50%;
    border: 3px solid #800000;
    border-color: #800000 transparent #800000 transparent;
    animation: loader 1.2s linear infinite;
}
@keyframes loader {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
}

@media screen and (min-width : 200px ){
    div[class*="bannerList-carousel-container"]{
        width:100%;
        padding: 0px;
    }
}

@media screen and (min-width : 200px) and (max-width : 413px ){
    .hasImage{
        padding: 0px;
    }
}

@media screen and (min-width : 350px) and (max-width : 413px ){
    .hasImage{
        padding: 0px;
    }
}

@media screen and (min-width : 413px) and (max-width : 434px ){
    .hasImage{
        padding: 0px;
    }
}

@media screen and (min-width : 900px) and (max-width : 945px ){
    div[class*="bannerList-carousel-container"]{
        button[class*="slick-arrow slick-prev"]{
            left: -10px;
            // top: 45% !important;
        } 
        button[class*="slick-arrow slick-next"]{
            right: 5px;
            // top: 45% !important;
        }
    }
}

@media screen and (width : 280px) {
    .carouselBannerContainer{
    padding: 0% 10% 0% 10%;
    }
}

@media screen and (min-width : 1200px) {
    .container{
        max-width: 100%;
    }
    section[class*='list-feed-container'] {
        flex-direction: row !important;
        justify-content: center;
    }

    div[class*="bannerList-carousel-container"]{
        max-width:1500px;
    }
}

@media screen and (min-width : 700px) {
    .container{
        margin: 48px auto;
    }
}
